<!--
 * @Auth: linjituan
 * @Date: 2021-08-17 16:35:13
 * @LastEditors: linjituan
 * @LastEditTime: 2021-12-27 10:27:53
-->
<template>
  <div>
    <div class="print-expenses-wrap" id="expensesPrint" ref="peWrap">
      <img class="badge" :src="imgStatusUrl[data.approvalStatus]" alt="" />
      <div class="header">
        <div class="header-title">采购</div>
        <div class="header-bottom">
          <div class="header-bottom-item">{{ data.deptName }}</div>
          <div class="header-bottom-item">申请日期：{{ data.applyTime }}</div>
        </div>
      </div>
      <div>
        <table class="table">
          <tr class="td">
            <td class="tl">申请人</td>
            <td class="tr" colspan="5">{{ data.applicantName }}</td>
          </tr>
          <tr class="td">
            <td class="tl">费用类型</td>
            <td class="tr" colspan="5">{{ setExpenseType(data.expenseType) }}</td>
          </tr>
          <!-- <tr class="td">
            <td class="tl">申请金额</td>
            <td class="tr"></td>
          </tr> -->
          <tr class="td">
            <td class="tl">月费用加总</td>
            <td class="tr" colspan="5">{{ data.expenseTotal }}</td>
          </tr>
          <tr class="td">
            <td class="tl">附件</td>
            <td class="tr" colspan="5">
              <div v-for="item in data.billImageUrl" :key="item">{{ item }}</div>
            </td>
          </tr>
          <tr class="td purchase-detail">
            <td class="tl"></td>
            <td class="tr purchase-1">采购内容</td>
            <td class="tr purchase-21">数量</td>
            <td class="tr purchase-3">单位</td>
            <td class="tr purchase-4">单价</td>
            <td class="tr purchase-5">总价</td>
          </tr>
          <tr class="td purchase-detail" v-for="(item, index) in data.purchaseList" :key="index">
            <td class="tl">采购明细{{ index + 1 }}</td>
            <td class="tr purchase-1">{{ item.content }}</td>
            <td class="tr purchase-21">{{ item.quantity }}</td>
            <td class="tr purchase-3">{{ item.unit }}</td>
            <td class="tr purchase-4">{{ item.price }}</td>
            <td class="tr purchase-5">{{ item.amount }}</td>
          </tr>
          <tr class="td purchase-detail">
            <td class="tl"></td>
            <td class="tr purchase-1"></td>
            <td class="tr purchase-21"></td>
            <td class="tr purchase-3"></td>
            <td class="tr purchase-4"></td>
            <td class="tr purchase-5">
              <p>总价格</p>
              <p>{{ data.applyAmount }}</p>
            </td>
          </tr>
          <tr class="td">
            <td class="tl">交易对手方</td>
            <td class="tr" colspan="5">{{ data.counterparty }}</td>
          </tr>
          <tr class="td">
            <td class="tl">付款方式</td>
            <td class="tr" colspan="5">{{ setPaymentType(data.paymentType) }}</td>
          </tr>
          <tr class="td" v-if="data.actualPayer">
            <td class="tl">实际付款人</td>
            <td class="tr" colspan="5">{{ data.actualPayer }}</td>
          </tr>
          <tr class="td" v-if="data.remark">
            <td class="tl">备注说明</td>
            <td class="tr" colspan="5">{{ data.remark }}</td>
          </tr>
          <tr class="td purchase-approval">
            <td :rowspan="data.approvalRecordVos && data.approvalRecordVos.length + 1" class="tl">审批流程</td>
          </tr>
          <tr class="td purchase-approval" v-for="(item, index) in data.approvalRecordVos" :key="index">
            <td class="tr" colspan="5">
              <div class="float-left">{{ item.approvalBy }} {{ approvalStatusFormat(item) }}</div>
              <div class="float-right">{{ item.approvalTime }}</div>
            </td>
          </tr>
        </table>
      </div>
      <div class="footer">
        <div class="footer-cell">打印时间： {{ parseDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss') }}</div>
        <div class="footer-cell">打印人： {{ userInfo.userName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import Watermark from '@/components/watermark/index.js'
import { parseDateTime } from '@/utils/ruoyi'
import { mapState } from 'vuex'
import { getExpenses, getApprovalDetail } from '@/api/iot/expenses'
import base from './modules/base'

export default {
  props: {
    expenseId: {
      type: String,
      default: ''
    }
  },
  mixins: [base],
  computed: {
    ...mapState({
      userInfo: state => state.user.info
    })
  },
  watch: {
    expenseId(val) {
      if (val) {
        this.getEspense()
      }
    }
  },
  data() {
    return {
      data: {},
      urlListStr: ''
    }
  },
  mounted() {
    console.log('this.$refs.peWrap', this.$refs.peWrap.offsetTop)
    this.getDictData()
    if (this.expenseId) {
      this.getEspense()
    }
    // Watermark.set({ str: this.userInfo.userName, el: this.$refs.peWrap })
  },
  methods: {
    async getDictData() {
      await this.getDicts('iot_expense_type').then(response => {
        this.expenseTypeOptions = response.data
      })
      await this.getDicts('sys_pay_type').then(response => {
        this.paymentTypeOptions = response.data
      })
    },
    setExpenseType(expenseType) {
      return expenseType && this.selectDictLabel(this.expenseTypeOptions, expenseType)
    },
    setPaymentType(paymentType) {
      return paymentType && this.selectDictLabel(this.paymentTypeOptions, paymentType)
    },
    approvalStatusFormat(item) {
      switch (item.approvalAction) {
        case '2':
          return '拒绝'
        case '4':
          return '同意'
        case '6':
          return '暂停'
        default:
          return '流程中'
      }
    },
    parseDateTime,
    getEspense() {
      console.log('this.expenseId', this.expenseId)
      getExpenses(this.expenseId).then(response => {
        this.data = response.data
        this.data.purchaseList.forEach(p => {
          p.amount = (p.quantity * 1 * p.price * 1).toFixed(2)
        })
        if (!this.data.billImageUrl && this.data.billImage && this.data.billImage) {
          this.data.billImageUrl = this.data.billImage.split(',')
        }
        // if (this.data.billImageUrl && this.data.billImageUrl.length > 0) {
        //   this.urlListStr = this.data.billImageUrl.join(';')
        // }
        this.handleToApprove()
        this.$forceUpdate()
      })
    },
    handleToApprove() {
      getApprovalDetail(this.expenseId).then(response => {
        this.data.approvalRecordVos = response.approvalRecordVos
        this.$forceUpdate()
        this.$emit('readyPrint')
      })
    }
  },
  beforeDestroy() {
    // Watermark.set('', '')
  }
}
</script>

<style scoped lang="less">
.print-expenses-wrap {
  background: #fff;
  position: relative;
  padding: 40px;
  color: #000;
  .badge {
    position: absolute;
    width: 80px;
    height: 80px;
    right: 50px;
    top: 114px;
  }
  .header {
    margin-bottom: 10px;
    .header-title {
      font-size: 18px;
      text-align: center;
      color: #000;
      padding: 10px 0 20px;
    }
    .header-bottom {
      .header-bottom-item {
        display: inline-block;
        margin-right: 30px;
      }
    }
  }
  .table {
    width: 100%;
    border-bottom: 1px solid #333;
    .td {
      border-left: 1px solid #333;
      border-top: 1px solid #333;
      border-right: 1px solid #333;
      .tl {
        border-right: 1px solid #333;
        padding: 10px;
        white-space: nowrap;
      }
      .tr {
        padding: 10px;
      }
    }
    .purchase-detail {
      .tr {
        border-right: 1px solid #333;
        p {
          line-height: 1;
          margin: 0;
        }
      }
    }
    .purchase-approval {
      overflow: hidden;
      .tr {
        .float-left {
          float: left;
        }
        .float-right {
          float: right;
        }
      }
    }
  }
  .footer {
    margin-top: 20px;
    .footer-cell {
      display: inline-block;
      margin-right: 30px;
    }
  }
}
</style>
