var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "peWrap",
        staticClass: "print-expenses-wrap",
        attrs: { id: "expensesPrint" },
      },
      [
        _c("img", {
          staticClass: "badge",
          attrs: { src: _vm.imgStatusUrl[_vm.data.approvalStatus], alt: "" },
        }),
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "header-title" }, [_vm._v("采购")]),
          _c("div", { staticClass: "header-bottom" }, [
            _c("div", { staticClass: "header-bottom-item" }, [
              _vm._v(_vm._s(_vm.data.deptName)),
            ]),
            _c("div", { staticClass: "header-bottom-item" }, [
              _vm._v("申请日期：" + _vm._s(_vm.data.applyTime)),
            ]),
          ]),
        ]),
        _c("div", [
          _c(
            "table",
            { staticClass: "table" },
            [
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("申请人")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.data.applicantName)),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("费用类型")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.setExpenseType(_vm.data.expenseType))),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("月费用加总")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.data.expenseTotal)),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("附件")]),
                _c(
                  "td",
                  { staticClass: "tr", attrs: { colspan: "5" } },
                  _vm._l(_vm.data.billImageUrl, function (item) {
                    return _c("div", { key: item }, [_vm._v(_vm._s(item))])
                  }),
                  0
                ),
              ]),
              _vm._m(0),
              _vm._l(_vm.data.purchaseList, function (item, index) {
                return _c(
                  "tr",
                  { key: index, staticClass: "td purchase-detail" },
                  [
                    _c("td", { staticClass: "tl" }, [
                      _vm._v("采购明细" + _vm._s(index + 1)),
                    ]),
                    _c("td", { staticClass: "tr purchase-1" }, [
                      _vm._v(_vm._s(item.content)),
                    ]),
                    _c("td", { staticClass: "tr purchase-21" }, [
                      _vm._v(_vm._s(item.quantity)),
                    ]),
                    _c("td", { staticClass: "tr purchase-3" }, [
                      _vm._v(_vm._s(item.unit)),
                    ]),
                    _c("td", { staticClass: "tr purchase-4" }, [
                      _vm._v(_vm._s(item.price)),
                    ]),
                    _c("td", { staticClass: "tr purchase-5" }, [
                      _vm._v(_vm._s(item.amount)),
                    ]),
                  ]
                )
              }),
              _c("tr", { staticClass: "td purchase-detail" }, [
                _c("td", { staticClass: "tl" }),
                _c("td", { staticClass: "tr purchase-1" }),
                _c("td", { staticClass: "tr purchase-21" }),
                _c("td", { staticClass: "tr purchase-3" }),
                _c("td", { staticClass: "tr purchase-4" }),
                _c("td", { staticClass: "tr purchase-5" }, [
                  _c("p", [_vm._v("总价格")]),
                  _c("p", [_vm._v(_vm._s(_vm.data.applyAmount))]),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("交易对手方")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.data.counterparty)),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("付款方式")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.setPaymentType(_vm.data.paymentType))),
                ]),
              ]),
              _vm.data.actualPayer
                ? _c("tr", { staticClass: "td" }, [
                    _c("td", { staticClass: "tl" }, [_vm._v("实际付款人")]),
                    _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                      _vm._v(_vm._s(_vm.data.actualPayer)),
                    ]),
                  ])
                : _vm._e(),
              _vm.data.remark
                ? _c("tr", { staticClass: "td" }, [
                    _c("td", { staticClass: "tl" }, [_vm._v("备注说明")]),
                    _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                      _vm._v(_vm._s(_vm.data.remark)),
                    ]),
                  ])
                : _vm._e(),
              _c("tr", { staticClass: "td purchase-approval" }, [
                _c(
                  "td",
                  {
                    staticClass: "tl",
                    attrs: {
                      rowspan:
                        _vm.data.approvalRecordVos &&
                        _vm.data.approvalRecordVos.length + 1,
                    },
                  },
                  [_vm._v("审批流程")]
                ),
              ]),
              _vm._l(_vm.data.approvalRecordVos, function (item, index) {
                return _c(
                  "tr",
                  { key: index, staticClass: "td purchase-approval" },
                  [
                    _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                      _c("div", { staticClass: "float-left" }, [
                        _vm._v(
                          _vm._s(item.approvalBy) +
                            " " +
                            _vm._s(_vm.approvalStatusFormat(item))
                        ),
                      ]),
                      _c("div", { staticClass: "float-right" }, [
                        _vm._v(_vm._s(item.approvalTime)),
                      ]),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "footer-cell" }, [
            _vm._v(
              "打印时间： " +
                _vm._s(_vm.parseDateTime(new Date(), "yyyy-MM-dd HH:mm:ss"))
            ),
          ]),
          _c("div", { staticClass: "footer-cell" }, [
            _vm._v("打印人： " + _vm._s(_vm.userInfo.userName)),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "td purchase-detail" }, [
      _c("td", { staticClass: "tl" }),
      _c("td", { staticClass: "tr purchase-1" }, [_vm._v("采购内容")]),
      _c("td", { staticClass: "tr purchase-21" }, [_vm._v("数量")]),
      _c("td", { staticClass: "tr purchase-3" }, [_vm._v("单位")]),
      _c("td", { staticClass: "tr purchase-4" }, [_vm._v("单价")]),
      _c("td", { staticClass: "tr purchase-5" }, [_vm._v("总价")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }